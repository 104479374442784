<template>
  <div class="video-new-project">
    <div class="video-new-project-part header">
      <h4 v-if="mainProjectSelected">{{ t('project.new_project.products_title_step_2') }} : {{mainProjectSelected}}</h4>
      <h4 v-else>{{ t('project.new_project.products_title_step_2') }}</h4>
      <p>{{ t(`project.new_project.${productSelected.key}.products_description_step_2`) }}</p>
    </div>
    
    <h4>{{ t('project.new_project.your_product_title') }}</h4>
    <div class="video-new-project-part product">
      <div class="video-new-project-part product-info product-tab">
        <img :src="require(`../../../assets/img/products/${productSelected.key}-selected.svg`)" alt="">
        <p>{{ productSelected.name }}</p>
      </div>
      <div class="video-new-project-part product-info language" @click="openOptionsProjectModal()">
        <p v-if="!languageSelected">{{ t('project.new_project.fill_language') }} <i class="asterisk red mini icon"></i></p>
        <span v-else v-for="(language, i) in languages" :key="i">
          <p v-show="language.id == languageSelected">
            <img class="mini round-flag" :src="require(`../../../assets/img/flags/${language.flag_iso}.png`)">
            {{ language.name }}
          </p>
        </span>
        <i class="pen icon"></i>
      </div>

      <div v-if="!productSelected.key.includes('manual')" class="video-new-project-part product-info options-tab">
        <p>
          <i class="info grey circle icon"></i>
          {{ t('project.new_project.options.cpl_title') }} - {{cplSelected}}
        </p>
      </div>
      <div v-if="!productSelected.key.includes('manual')" class="video-new-project-part product-info options-tab">
        <p>
          <i class="info grey circle icon"></i>
          {{ t('project.new_project.options.lines_title') }} - {{lineSelected}}
        </p>
      </div>
      <div v-if="!productSelected.key.includes('manual')" class="video-new-project-part product-info options-tab">
        <p v-show="predefinedInstructions.length > 0 && productSelected.key.includes('proofreading') || customInstructions && productSelected.key.includes('proofreading')">
          <i class="info grey circle icon"></i>
          {{ t('project.new_project.options.instructions_field') }}
        </p> 
        <p v-show="!predefinedInstructions.length > 0 && !customInstructions && productSelected.key.includes('proofreading')">
          <i class="info yellow circle icon"></i>
          {{ t('project.new_project.options.instructions_not_field') }}
        </p>
      </div>
      
      <div class="video-new-project-part product-info options-button" @click="openOptionsProjectModal()">
        <button class="ui tiny little-text green authot button">{{ t('project.new_project.btn_options') }}</button>
      </div>
    </div>
    
    <h4>{{ t('project.new_project.your_videos_title') }}</h4>
    <div class="video-new-project-part files">
      <div>
        <div>
          <CMultiUploader
            ref="multiUploaderNewProject"
            id="multi-uploader-new-project"
            dropId="drop-multi-uploader-new-project"
            browseId="browse-multi-uploader-new-project"
            :fileAccepted="['3g2','3gp','3gp2','3gpp','4xm','amv','applehttp','asf','avi','avs','bink','c93','cdg','dirac','dpx','dv','dv1394','dvd','dvf','dxa','ffm','film','flv','gxf','h261','h263','h264','iff','ivf','lmlm4','m1a','m2a','m4a','m4b','m4p','m4r','m4v','matroska','mj2','mka','mks','mkv','mlv','mov','mp1','mp4','mpeg','mpeg1video','mpeg2video','mpegts','mpegtsraw','mpegvideo','mpg','mpv','mtv', 'mts','mvi','mxf','mxg','nsv','nut','nuv','ogm','ogv','ogx','oss','pva','r3d','raw','rm','rtp','rtsp','siff','smk','stream','svcd','swf','thp','ts','vc1','vob','vox','webm','wmv','wtv','xa','xmv']"
            :additionalFileAccepted="['srt', 'ass', 'vtt', 'webvtt', 'txt']"
            :acceptSubfile="productSelected.key.includes('manual')"
            :defaultLanguage="languageSelected"
            :languages="languages"
            @emitInfoFile="createProject"
            @emitFileDestroy="removeProject"
            @emitFilepath="postProject"
            @emitUpdateSubDocLanguage="updateSubtitleLanguage"
          >
          </CMultiUploader>
        </div>
      </div>
    </div>
    <div class="video-new-project-part actions">
      <button class="ui green button" :class="{'disabled' : !formComplete}" @click="uploadProjects()">{{ t('project.new_project.btn_start') }}</button>
    </div>
    <COptionsProjectModal ref="optionsProjectModal"></COptionsProjectModal>
    <CUploadStatusModal ref="uploadStatusModal"></CUploadStatusModal>
  </div>
</template>

<script>
import CMultiUploader from '../../uploader/CMultiUploader.vue';
import COptionsProjectModal from '../modals/COptionsProjectModal.vue';
import CUploadStatusModal from '../modals/CUploadStatusModal.vue'
import { truncateKey, isFilled } from '../../../utils/functions';

export default {
  props: ["current_user"],
  data() {
    return {
      defaultCpl: 40,
      defaultLine: 2,
      formComplete: false
    }
  },
  computed: {
    projectStep() {
      return this.$store.getters['newProjects/project_step']
    },
    productSelected() {
      return this.$store.getters['newProjects/product_selected']
    },
    languageSelected() {
      return this.$store.getters['newProjects/language_selected']
    },
    cplSelected() {
      return this.$store.getters['newProjects/cpl_selected']
    },
    lineSelected() {
      return this.$store.getters['newProjects/line_selected']
    },
    mainProjectSelected() {
      return this.$store.getters['newProjects/main_project_selected']
    },
    predefinedInstructions() {
      return this.$store.getters['newProjects/predefined_instructions']
    },
    customInstructions() {
      return this.$store.getters['newProjects/custom_instructions']
    },
    project() {
      const projects = this.$store.getters['newProjects/projects'];
      return projects.find(project => project.id == this.project_id);
    },
    languages() {
      return this.$store.getters['newProjects/languages']
    },
    userProjects() {
      return this.$store.getters['newProjects/user_projects']
    },
    projects() {
      return this.$store.getters['newProjects/projects'];
    },
    url_projects() {
      return this.$store.getters['newProjects/url_projects'];
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  watch: {
    languageSelected() {
      this.checkFormComplete()
    },
    projects: {
      handler() {
        this.checkFormComplete() 
      },
      deep: true
    },
    url_projects: {
      handler() {
        this.checkFormComplete() 
      },
      deep: true
    }

  },
  methods: {
    checkFormComplete() {
      if(!this.productSelected.key.includes('manual')) {
        this.formComplete = this.projects.length > 0 && this.languageSelected || this.url_projects.length > 0 && this.languageSelected ? true : false
      } else {
        let projectsComplete = false
        let urlProjectsComplete = false
        if(this.projects.length == 0 && this.url_projects.length == 0) {
          projectsComplete = false
          urlProjectsComplete = false
        } else {
          if(this.projects.length > 0){
            this.projects.map(project => project.subtitles_attributes.length > 0 ? projectsComplete = true : projectsComplete = false)
          } else {
            projectsComplete = true
          }
          if(this.url_projects.length > 0){
            this.url_projects.map(project => project.subtitles_attributes.length > 0 ? urlProjectsComplete = true : urlProjectsComplete = false)
          } else {
            urlProjectsComplete = true
          }          
        }
        this.formComplete = projectsComplete && urlProjectsComplete && this.languageSelected
      }
    },
    openOptionsProjectModal() {
      this.$refs["optionsProjectModal"].openModal()
    },
    getChoosedLanguage(languageId) {
      this.project.language_id = languageId;
    },
    isSpeechmaticsLanguage(languageId) {
      let lang = this.languages.find(language => language.id == languageId);
      return lang.speechmatics;
    },
    truncateString(stringData) {
      return truncateKey(stringData)
    },
    createProject(infoFile){
      infoFile.url && infoFile.parentFileId ?
        this.$store.commit('newProjects/ADD_SUBTITLE_TO_URL', infoFile) :
        infoFile.url ?
          this.$store.commit('newProjects/CREATE_URL_PROJECT', infoFile) :
          infoFile.parentFileId ?
            this.$store.commit('newProjects/ADD_SUBTITLE_TO_PROJECT', infoFile) : 
            this.$store.commit('newProjects/CREATE_PROJECT', infoFile);
    },
    removeProject(projectId) {
      projectId.url && projectId.parentFileId ?
        this.$store.commit('newProjects/REMOVE_SUBTITLE_URL', projectId) :
        projectId.url ? 
          this.$store.commit('newProjects/REMOVE_URL_PROJECT', projectId) :
          projectId.parentFileId ?
            this.$store.commit('newProjects/REMOVE_SUBTITLE_PROJECT', projectId):
            this.$store.commit('newProjects/REMOVE_PROJECT', { project_id: projectId });
    },
    uploadProjects() {
      this.$refs["multiUploaderNewProject"].upload()
      if(!this.productSelected.key.includes('manual')) this.url_projects.map(project => this.$store.dispatch("newProjects/postProject", project))
      this.$refs["uploadStatusModal"].openModal()
    },
    findProjectToUpdate(infoFile) {
      let projectFound
      this.projects.map(project => {
        if(project.id === infoFile.file_id) {
          project.filepath = infoFile.file_path;
          projectFound = {project_id: project.id, project_type: "project"}
        } else {
          project.subtitles_attributes.map(subtitle => {
            if(subtitle.id === infoFile.file_id) {
              subtitle.filepath = infoFile.file_path;
              projectFound = {project_id: project.id, project_type: "project-subtitle"}
            }
          })
        }
      })
      if(!projectFound) {
        this.url_projects.map(project => {
          project.subtitles_attributes.map(subtitle => {
            if(subtitle.id === infoFile.file_id) {
              subtitle.filepath = infoFile.file_path
              projectFound = {project_id: project.id, project_type: "url"}
            }
          })
        })
      }
      return projectFound
    },
    checkIfProjectComplete(projectInfo) {
      let projectFilepaths = []
      if(projectInfo.project_type == "project" || projectInfo.project_type == "project-subtitle") {
        let project = this.projects.find(project => project.id == projectInfo.project_id)
        projectFilepaths.push(project.filepath);
        project.subtitles_attributes.map(subtitle => projectFilepaths.push(subtitle.filepath))
      } else {
        let url = this.url_projects.find(url => url.id == projectInfo.project_id)
        url.subtitles_attributes.map(subtitle => projectFilepaths.push(subtitle.filepath))
      }
      return projectFilepaths.every(isFilled)
    },
    postProject(infoFile) {
      if(this.productSelected.key.includes('manual')) {
        let projectToUpdate = this.findProjectToUpdate(infoFile)
        this.$store.commit("newProjects/UPDATE_FILEPATH", { project_to_update: projectToUpdate, info_file: infoFile })
        if(this.checkIfProjectComplete(projectToUpdate)) {
          this.$store.dispatch("newProjects/postProject", projectToUpdate)
        }
      } else {
        this.$store.dispatch("newProjects/postProject", infoFile)
      }
    },
    updateSubtitleLanguage(infoFile) {
      infoFile.url ? 
        this.$store.commit("newProjects/UPDATE_URL_SUBTITLE_LANGUAGE", infoFile) :
        this.$store.commit("newProjects/UPDATE_PROJECT_SUBTITLE_LANGUAGE", infoFile)
    }
  },
  mounted() {
    this.$store.dispatch('newProjects/getAllLanguages');
    this.$store.dispatch('newProjects/setAllUserProjects');
    this.$store.dispatch('credit/getAllCredits', this.current_user);
    this.openOptionsProjectModal();
  },
  components: {
    CMultiUploader,
    COptionsProjectModal,
    CUploadStatusModal
  }
}
</script>